<template>
  <CContainer id="Packing">
    <CForm>
      <CCard>
        <CCardHeader class="d-flex justify-content-between align-items-center">
          <h5 class="mb-0">{{ $t('Navigation.System/Packing') }}</h5>
          <div class="card-header-actions d-flex">
            <CButton variant="outline" shape="pill" color="success" type="button" @click="Add()">
              <CIcon size="sm" class="mr-1" name="cil-plus" /> {{ $t('Logistics.SetPackage') }}
            </CButton>
          </div>
        </CCardHeader>
        <CCardBody>
          <CRow v-if="Object.keys(List).length > 0">
            <CCol v-for="(Data, ID) in List" md="6" col="12" :key="ID">
              <CCard>
                <CCardHeader class="py-1 px-3 d-flex justify-content-between align-items-center">
                  <CInput v-if="Data.Editable === true" class="mb-0" v-model="Data.Name" size="sm" :plaintext="!Data.Editable" />
                  <h6 v-else class="mb-0">{{ Data.Name }}</h6>
                  <div class="card-header-actions d-flex align-items-center">
                    <CButton v-if="Data.Editable === true" color="success" type="button" size="sm" class="py-0 px-2 mr-1" @click="Edit(ID)">
                      {{ $t('Global.Save') }}
                    </CButton>
                    <CSwitch color="success" size="sm" :checked.sync="Data.Status" @update:checked="Edit(ID)" />
                    <CDropdown size="sm" color="transparent" addTogglerClasses="text-dark" :caret="false" placement="bottom-end">
                      <template #toggler-content>
                        <CIcon size="sm" name="cil-options" />
                      </template>
                      <CDropdownItem @click="Data.Editable = !Data.Editable">
                        {{ Data.Editable ? '取消' : '' }}編輯
                      </CDropdownItem>
                      <CDropdownItem @click="Delete(ID)">
                        刪除
                      </CDropdownItem>
                    </CDropdown>
                  </div>
                </CCardHeader>
                <CCardBody class="p-3">
                  <div class="d-flex">
                    <CIcon name="cil-inbox" size="custom" customClasses="Box" class="mr-4" />
                    <CRow>
                      <CCol col="6">
                        <CInput class="mb-1" type="number" v-model="Data.Length" size="sm" :label="'長度'" horizontal :plaintext="!Data.Editable" :append="'CM'" />
                        <CInput class="mb-1" type="number" v-model="Data.Width" size="sm" :label="'寬度'" horizontal :plaintext="!Data.Editable" :append="'CM'" />
                      </CCol>
                      <CCol col="6">
                        <CInput class="mb-1" type="number" v-model="Data.Height" size="sm" :label="'高度'" horizontal :plaintext="!Data.Editable" :append="'CM'" />
<!--                        <CInput class="mb-1" :value="CalculateVolumeWeight(ID)" size="sm" :label="'材積'" horizontal :plaintext="true" :append="'KG'" />-->
                        <CInput class="mb-1" type="number" v-model="Data.MaxWeight" size="sm" :label="'材積重'" horizontal :plaintext="!Data.Editable" :append="'KG'" />
                      </CCol>
                    </CRow>
                  </div>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CAlert v-if="Object.keys(List).length === 0 && !Loading" color="info">
            <CIcon name="cil-bell" /> 尚未新增任何包裹規格
          </CAlert>
          <CElementCover
            v-if="(Loading === true)"
            :boundaries="[{ sides: ['center', 'center'], query: '#CreateSubmit' }]"
            :opacity="0.8"
          >
            <CSpinner color="info"/>
          </CElementCover>
        </CCardBody>
      </CCard>
    </CForm>
  </CContainer>
</template>

<route>
{
"meta": {
"label": "包裹裝箱設定"
}
}
</route>

<script>
export default {
  name: 'PackagePropertySetting',
  layout: 'manage',
  data () {
    return {
      Loading: false,
      Features: [],
      List: {},
      CurrentEditIndex: null,
      PackingData: {
        Width: 1,
        Height: 1,
        Length: 1,
        MaxWeight: 1,
        Status: true
      }
    }
  },
  mounted() {
    this.$Progress.start()
    this.Features = this.$store.state.user.permission.Features.System.Logistics || []
    return Promise.all([
      this.GetList()
    ]).then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    GetList() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/packing/list',
        method: 'get'
      }).then(({data}) => {
        this.Loading = false
        this.List = data
        Object.keys(this.List).forEach((key) => {
          this.$set(this.List[key], 'Editable', false)
        })
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    Add () {
      if (Object.keys(this.List).length === 10) {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: '最多只能設定10組包裹規格',
          type: 'warning'
        })
        return false
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/system/packing/add',
        method: 'post',
        data: {
          PackingData: {
            Name: '包裹' + (Object.keys(this.List).length + 1),
            Length: 0,
            Height: 0,
            Width: 0,
            Status: true
          }
        }
      }).then(() => {
        this.Loading = false
        this.GetList()
      }).catch((error) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + error.msg,
          type: (error.type ? error.type : 'error')
        })
        throw error
      })
    },
    Edit (ID) {
      return this.$store.dispatch('InnerRequest', {
        url: '/system/packing/set',
        method: 'post',
        data: {
          ID,
          PackingData: this.List[ID]
        }
      }).then(() => {
        this.List[ID].Editable = false
        this.Loading = false
      }).catch((error) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + error.msg,
          type: (error.type ? error.type : 'error')
        })
        throw error
      })
    },
    Delete(ID) {
      return this.$swal({
        icon: 'info',
        title: this.$t('Message.AskDelete'),
        text: '此操作將不可回覆',
        showCancelButton: true,
        confirmButtonColor: '#2eb85c',
        confirmButtonText: this.$t('Global.Confirm'),
        cancelButtonText: this.$t('Global.Cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.Submit = true
          return this.$store.dispatch('InnerRequest', {
            url: '/system/packing/delete',
            method: 'post',
            data: {
              ID
            }
          }).then(() => {
            this.GetList()
            this.Submit = false
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Success'),
              text: this.$t('Message.Global/DeleteSuccess'),
              type: 'success'
            })
          }).catch((error) => {
            this.Submit = false
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Error'),
              text: this.$t('Message.Global/DeleteFail') + error.msg,
              type: 'error'
            })
            throw error
          })
        }
      })
    },
    CalculateVolumeWeight (ID) {
      const CurrentData = this.List[ID]
      return Math.round(CurrentData.Length * CurrentData.Height * CurrentData.Width / 6000 * 10) / 10
    }
  }
}
</script>

<style lang="scss">
#Packing {
  .Box {
    width: 60px;
    height: 60px;
  }
  .input-group-sm {
    > .form-control-plaintext {
      height: calc(1.5em + 0.5rem + 2px);
      padding: 0.25rem 0.5rem;
      font-size: 0.765625rem;
      line-height: 1.5;
      border-radius: 0.2rem;
      + .input-group-append {
        .input-group-text {
          background-color: transparent;
          border-color: transparent;
        }
      }
    }
  }
}
</style>
